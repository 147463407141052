<template>
  <v-container fluid>
    <h2>Geração de chave BIG PDV</h2>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center">
        <v-col cols="12" md="3">
          <v-text-field
            :counter="14"
            maxlength="14"
            v-model="cnpj"
            append-icon="mdi-check-bold"
            @click:append="searchClient"
            label="CNPJ"
            :rules="[rules.required, rules.min]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" v-if="client.cnpj">
          <v-select
            :items="itemsControl"
            v-model="control"
            label="Controle"
            required
            no-data-text="Nenhum controle encontrado"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" v-if="client.cnpj">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                label="Vencimento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="date"
              @input="menu = false"
              :max="limitDue"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" v-if="client.cnpj">
          <v-btn
            :disabled="!valid"
            depressed
            color="primary"
            @click="generateKey"
          >
            Gerar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="client.cnpj">
      <v-col cols="12">
        <v-card width="750" height="auto">
          <v-card-title class="text-h5"> Dados do cliente </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row class="pa-2">
                <span class="font-weight-black mr-2">Razão social: </span>
                {{ client.name }}
              </v-row>
              <v-row class="pa-2">
                <span class="font-weight-black mr-2">Endereço: </span>
                {{
                  `${client.address}, ${client.number}, ${client.district} - ${client.city}/${client.state}`
                }}
              </v-row>
              <v-row class="pa-2" v-if="key">
                <span class="font-weight-black mr-2">Chave: </span>{{ key }}
                <v-icon
                  color="blue-grey darken-2"
                  class="ma-2"
                  small
                  @click="copyToClickBoard"
                >
                  mdi-content-copy
                </v-icon>
              </v-row>
            </v-container>
            <v-snackbar
              v-model="snackbar"
              :timeout="3000"
              :color="colorSnack"
              outlined
            >
              {{ text }}
            </v-snackbar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { baseApiUrl } from "@/global";
moment.locale("pt-br");
export default {
  name: "GenerateKeyBig",
  data: () => ({
    client: {},
    valid: false,
    cnpj: "",
    control: "",
    itemsControl: [],
    menu: false,
    snackbar: false,
    test: false,
    key: null,
    text: null,
    colorSnack: "",
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => (v && v.length >= 14) || "Minimo 14 caracteres!",
    },
    date: moment(new Date()).format("yyyy-MM-DD"),
  }),
  methods: {
    async searchClient() {
      const url = `${baseApiUrl}/key/client`;
      this.itemsControl = []
      axios
        .get(url, {
          params: {
            cnpj: this.cnpj,
            program: "BIGPDV",
          },
        })
        .then((res) => {
          this.client = res.data;
          res.data.contracts.map((contract) =>
            this.itemsControl.unshift(contract.control)
          );
        })
        .catch((error) => alert(error.response.data));
    },
    async generateKey() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      if (!this.control) {
        this.openSnack("Cliente com controle inválido!", "error");
      } else {
        const url = `${baseApiUrl}/keys/big-pdv`;
        axios
          .post(url, {
            cnpj: this.client.cnpj,
            due: moment(this.date).format(),
            control: this.control,
          })
          .then((res) => (this.key = res.data.key))
          .catch((error) => {
            if (error.response) {
              this.openSnack(error.response.data, "error");
            }
          });
      }
    },
    copyToClickBoard() {
      navigator.clipboard
        .writeText(this.key)
        .then(() => {
          this.openSnack(
            "Chave copiada para area de transferência.",
            "success"
          );
        })
        .catch((err) => {
          alert("Erro ao copiar a chave:", err);
        });
    },
    openSnack(text, color) {
      this.text = text;
      this.colorSnack = color;
      this.snackbar = true;
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("dddd, ll") : "";
    },
    dateFormatted() {
      return moment(this.date).format();
    },
    limitDue() {
      const due = Math.floor(Date.now() / 1000 + 60 * 60 * 24 * 7) * 1000;
      if (this.test) {
        return moment(due).format("yyyy-MM-DD");
      } else {
        return undefined;
      }
    },
  },
  watch: {
    test() {
      if (this.test) {
        this.date = this.limitDue;
      }
    },
  },
};
</script>

<style>
.v-card__text {
  font-size: 16px !important;
}
</style>
